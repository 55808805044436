import React, { useEffect } from "react";
import { Apple, ShoppingBag, Mail } from "lucide-react";
import { useNavigate } from "react-router-dom";

const IOSUpdateUrl =
  "https://apps.apple.com/us/app/desi-bazaar-app/id6502737877";

const DesiBazaarLanding: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const smoothScroll = (e: Event) => {
      e.preventDefault();
      const target = e.target as HTMLAnchorElement;
      const targetId = target.getAttribute("href")?.substring(1);
      if (targetId) {
        const element = document.getElementById(targetId);
        element?.scrollIntoView({ behavior: "smooth" });
      }
    };

    const links = document.querySelectorAll('nav a[href^="#"]');
    links.forEach((link) => {
      link.addEventListener("click", smoothScroll);
    });

    return () => {
      links.forEach((link) => {
        link.removeEventListener("click", smoothScroll);
      });
    };
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <header className="fixed z-50 w-full bg-white shadow-md">
        <div className="container flex items-center justify-between px-4 py-4 mx-auto">
          <div className="flex items-center">
            <img
              className="w-auto h-12"
              src="/img/logo/logo.png"
              alt="DesiBazaarApp"
            />
          </div>
          <nav>
            <ul className="flex space-x-4">
              <li>
                <a
                  href="#home"
                  className="text-gray-600 hover:text-pink-600"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/sell");
                  }}
                >
                  Sell
                </a>
              </li>
              <li>
                <a href="#about" className="text-gray-600 hover:text-pink-600">
                  About
                </a>
              </li>
              <li>
                <a
                  href="#features"
                  className="text-gray-600 hover:text-pink-600"
                >
                  Features
                </a>
              </li>
              {/* <li><a href="#contact" className="text-gray-600 hover:text-pink-600">Contact</a></li> */}
            </ul>
          </nav>
        </div>
      </header>

      <main className="flex-grow pt-20">
        <section
          id="home"
          className="relative h-[90vh] flex items-center justify-center overflow-hidden"
        >
          <div
            className="absolute inset-0 bg-gradient-to-br from-pink-400 via-purple-500 to-indigo-600"
            style={{
              backgroundSize: "400% 400%",
              animation: "gradient-animation 15s ease infinite",
            }}
          >
            <div className="absolute inset-0 opacity-30">
              <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1NiIgaGVpZ2h0PSIxMDAiPgo8cmVjdCB3aWR0aD0iNTYiIGhlaWdodD0iMTAwIiBmaWxsPSIjZmZmZmZmMjAiPjwvcmVjdD4KPHBhdGggZD0iTTI4IDY2TDAgNTBMMCAxNkwyOCAwTDU2IDE2TDU2IDUwTDI4IDY2TDI4IDEwMCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmZmZmNDAiIHN0cm9rZS13aWR0aD0iMiI+PC9wYXRoPgo8cGF0aCBkPSJNMjggMEwyOCAxMDBNMjggMEwwIDE2TTI4IDBMNTYgMTZNMjggNjZMNTYgNTBNMjggNjZMMCA1ME0yOCAwTDI4IDM0TDAgNTAiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZmZmZjQwIiBzdHJva2Utd2lkdGg9IjIiPjwvcGF0aD4KPC9zdmc+')] bg-repeat"></div>
            </div>
          </div>
          <div className="relative z-10 px-4 text-center">
            <h2 className="mb-4 text-5xl font-bold text-white md:text-6xl animate-fade-in-down">
              Welcome to DesiBazaarApp
            </h2>
            <p className="mb-8 text-xl text-white md:text-2xl animate-fade-in-up">
              Your go-to destination for preloved South Asian designer fashion
            </p>
            <a
              href="#about"
              className="px-8 py-4 text-lg font-semibold text-purple-600 transition duration-300 bg-white rounded-full hover:bg-purple-100 animate-pulse"
            >
              Learn More
            </a>
            <div className="mt-8">
              <p className="mb-4 text-white">Download Our App</p>
              <div className="flex justify-center space-x-4">
                <button
                  onClick={() => window.open(IOSUpdateUrl, "_blank")}
                  className="flex items-center px-4 py-2 text-white transition duration-300 bg-black rounded-lg hover:bg-gray-800"
                >
                  <Apple className="mr-2" />
                  <span>App Store</span>
                </button>
                <button
                  disabled
                  className="flex items-center px-4 py-2 text-white bg-gray-700 rounded-lg opacity-50 cursor-not-allowed"
                >
                  <ShoppingBag className="mr-2" />
                  <span>Play Store (Soon)</span>
                </button>
              </div>
            </div>
          </div>
        </section>

        <section id="about" className="py-16 mb-12 bg-white">
          <div className="container px-4 mx-auto">
            <h3 className="mb-8 text-3xl font-bold text-center text-gray-800">
              About DesiBazaarApp
            </h3>
            <p className="max-w-3xl mx-auto mb-4 text-center text-gray-600">
              DesiBazaarApp is your ultimate marketplace for buying and selling
              preloved South Asian designer fashion, including clothing, purses,
              shoes, accessories, and more. We cater to fashion lovers who seek
              quality, sustainability, and affordability, all in one place.
            </p>
            <p className="max-w-3xl mx-auto mb-4 text-center text-gray-600">
              Browse through a wide variety of gently used or preloved articles
              from some of the most beloved South Asian designer brands. Whether
              you're looking for a saree for a special occasion, a handbag to
              complete your outfit, or a pair of shoes to make a statement,
              DesiBazaarApp has something for everyone.
            </p>
          </div>
        </section>

        <section id="features" className="py-16 mb-12 bg-gray-100">
          <div className="container px-4 mx-auto">
            <h3 className="mb-8 text-3xl font-bold text-center text-gray-800">
              Key Features
            </h3>
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4">
              <FeatureCard
                title="Find Unique Items"
                description="Explore exclusive South Asian designer clothing, accessories, and more, all in one place."
              />
              <FeatureCard
                title="Bid or Buy"
                description="Participate in auctions or purchase items at fixed prices, making it easy to shop the way you prefer."
              />
              <FeatureCard
                title="Sell Preloved Fashion"
                description="Have items you no longer use? List them on DesiBazaarApp and find new homes for your preloved fashion."
              />
              <FeatureCard
                title="Easy Search"
                description="Filter by category, designer, or price range to quickly find what you're looking for."
              />
            </div>
          </div>
        </section>

        <section id="contact" className="py-16 bg-white">
          <div className="container px-4 mx-auto">
            <h3 className="mb-8 text-3xl font-bold text-center text-gray-800">
              Contact Us
            </h3>
            <div className="max-w-md p-8 mx-auto bg-gray-100 rounded-lg shadow-md">
              <p className="mb-4 text-center text-gray-700">
                Have questions or need assistance? We're here to help!
              </p>
              <div className="flex items-center justify-center">
                <Mail className="mr-2 text-pink-600" />
                <a
                  href="mailto:info@desibazaarapp.com"
                  className="text-pink-600 hover:underline"
                >
                  info@desibazaarapp.com
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="text-white bg-gray-800">
        <div className="container px-4 py-8 mx-auto">
          <div className="flex flex-col items-center justify-between md:flex-row">
            <div className="mb-4 md:mb-0">
              <h4 className="mb-2 text-xl font-bold">Download Our App</h4>
              <div className="flex space-x-4">
                <button
                  onClick={() => window.open(IOSUpdateUrl, "_blank")}
                  className="flex items-center px-4 py-2 text-white transition duration-300 bg-black rounded-lg hover:bg-gray-700"
                >
                  <Apple className="mr-2" />
                  <span>App Store</span>
                </button>
                <button
                  disabled
                  className="flex items-center px-4 py-2 text-white bg-gray-700 rounded-lg opacity-50 cursor-not-allowed"
                >
                  <ShoppingBag className="mr-2" />
                  <span>Play Store (Coming Very Soon)</span>
                </button>
              </div>
            </div>
            <div>
              <ul className="flex space-x-4">
                <li>
                  <a
                    href="/privacy"
                    className="hover:text-pink-400"
                    onClick={(e) => {
                      e?.preventDefault();
                      navigate("/privacy");
                    }}
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    href="/privacy"
                    className="hover:text-pink-400"
                    onClick={(e) => {
                      e?.preventDefault();
                      navigate("/privacy");
                    }}
                  >
                    Terms of Service
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-8 text-center text-gray-400">
            &copy; 2024 DesiBazaarApp. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
};

const FeatureCard: React.FC<{ title: string; description: string }> = ({
  title,
  description,
}) => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h4 className="mb-2 text-xl font-semibold text-pink-600">{title}</h4>
      <p className="text-gray-600">{description}</p>
    </div>
  );
};

export default DesiBazaarLanding;
