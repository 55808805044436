import { useState } from "react";
import { getProductsFilter } from "../../api/product/product-interface";
import { getProducts } from "../../api/product/product";
import { category } from "../useCategories/useCategories";
import { brand } from "../useBrands/useBrands";

export interface bid {
	product: any;
	_id: string;
	status: 'completed' | 'pending'
}

export interface productImages {
  url: string;
  mediaType: string;
  _id: string;
}

export type productMeasurements = {
  name: string;
  measurement: number;
  _id: string;
};

export interface product {
  _id: string;
  images: productImages[];
	coverImages?: productImages[];
  name?: string;
  description?: string;
  active?: boolean;
  createdBy?: string | any;
  categories?: category[];
  brands?: brand[];
  sizes?: string[];
  condition?: string;
  hasOldPricing?: boolean;
  price?: number;
	buyingPrice?: number;
	sellingPrice?: number;
  oldPrice?: number;
  allowToBeBided?: boolean;
  measurements?: productMeasurements[];
  createdAt?: string;
  updatedAt?: string;
  height?: number;
  quantity?: number;
  isAvailable?: boolean;
	productIssuer?: "fromCreate" | "fromSell"
	isSold?: boolean;
	color?: string;
}

const useProducts = () => {
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [products, setProducts] = useState<product[]>([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);

  const fetchProducts = async (
    filter?: getProductsFilter,
    callback?: () => void
  ) => {
    try {
      setLoadingProducts(true);
      const res = await getProducts(filter);
      const { docs, hasNextPage, totalDocs } = res.data?.data;
      const newProducts = docs.map((item: product) => ({
        ...item,
        // quantity: 10
        quantity: item?.quantity ?? 1,
      }));
      setProducts(newProducts);
      setLoadingProducts(false);
      setHasNextPage(hasNextPage);
      setTotalDocs(totalDocs);
      if (callback) {
        callback();
      }
    } catch (error) {
      setLoadingProducts(false);
      console.log(error);
      if (callback) {
        callback();
      }
    }
  };

  return { loadingProducts, products, fetchProducts, hasNextPage, totalDocs };
};

export default useProducts;
